import React, { FC } from "react";
import { theme } from "../../theme";
import HeaderWithImage from "../../components/composites/HeaderWithImage";
import Description from "../../components/elements/Description";
import Heading from "../../components/elements/Heading";

interface BannerProps {
  bannerData: any;
}
const Banner: FC<BannerProps> = ({ bannerData }: any) => {

  return (
    <>
      <HeaderWithImage
        bgImage={bannerData?.bgImage}
        bgThumbnail={bannerData?.thumbnail}
      >
        <Heading HeadingType={"banner"} textAlign={"center"} lineHeight={1.2} textFontWeight={theme.fontWeight.light} boldFontWeight={theme.fontWeight.bold} fontFamily={theme.font.families.oswald} fontSize={'3rem'} textColor={theme.colors.white} text={"ABOUT"} boldText={"US"} boldTextColor={theme.colors.white} />
        <Description textAlign="center" margin={`${theme.spacing[1]}rem auto`} lineHeight={2} textColor={theme.colors.white} textFontWeight={theme.fontWeight.light} text={bannerData?.desc} maxWidth={'65%'} />
      </HeaderWithImage>
    </>
  );
};

export default Banner;